<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space bg-white ourCompany beforeNone">
             <div class="container">
                 <div class="row justify-content-center">
                     <div class="col-12 col-lg-8 text-center">
                         <h1 class="bold mb-md-3">{{$t('about.text287')}} <span class="secondary">{{$t('about.text288')}}</span>{{$t('about.text289')}}</h1>
                         <p>{{$t('about.text290')}}</p>
                         <div class="dualButton text-center mt-4 dualbannerbtn d-flex align-items-center justify-content-center flex-wrap px-3">
                            <a v-if="store.user.access_token" :href="`${static_vars.affilateURL}register`" target="_blank" class="button fillBtn zulu_btn my-1 me-3">{{$t('about.text291')}}</a>
                            <a v-else :href="`${static_vars.affilateURL}register`" target="_blank" class="button fillBtn zulu_btn my-1 me-3">{{$t('about.text291')}}</a>
                            <a v-if="store.user.access_token" :href="(store.user?.access_token) ? `${static_vars.affilateURL}login?token=${store.user?.access_token}&refresh_token=${store.user?.refresh_token}` : `${static_vars.affilateURL}login`" class="button borderBtn zulu_btn my-1">{{$t('about.text292')}}</a>
                            <a v-else :href="`${static_vars.affilateURL}login`" target="_blank" class="button borderBtn zulu_btn my-1">{{$t('about.text292')}}</a>
                         </div>
                         <div class="zuluGuard my-3 my-md-5">
                             <v-lazy-image src="/assets/images/herobanner.png" :alt="$t('about.text369')" :title="$t('about.text370')" />
                         </div>
                     </div>
                 </div>
             </div>
         </section>
         <section class="blockElement space pt-0 whoWeAre">
             <div class="container">
                 <div class="row justify-content-center">
                     <div class="col-12 col-md-9 text-center">
                         <div class="boxed customTop">
                             <h2 class="mb-md-4">{{$t('about.text293')}}</h2>
                             <p>{{$t('about.text294')}}</p>
                         </div>
                     </div>
                 </div>
             </div>
         </section>
         <section class="blockElement space">
             <div class="container">
                 <div class="row">
                     <div class="col-12 mb-4 mb-md-5 text-center">
                         <h2>{{$t('about.text295')}}</h2>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4">
                         <div class="card-body h-100 bg-white p-3 mobCenter">
                             <span class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/create-account.svg" :alt="$t('about.text371')" :title="$t('about.text372')" /></span>
                             <h4 class="bold">{{$t('about.text296')}}</h4>  
                             <p>{{$t('about.text297')}}</p>     
                         </div> 
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4">
                         <div class="card-body h-100 bg-white p-3 mobCenter">
                             <span class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/promote.svg" :alt="$t('about.text373')" :title="$t('about.text374')"/></span>
                             <h4 class="bold">{{$t('about.text298')}}</h4>  
                             <p>{{$t('about.text299')}}</p>     
                         </div> 
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4">
                         <div class="card-body h-100 bg-white p-3 mobCenter">
                             <span class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/earn.svg" :alt="$t('about.text375')" :title="$t('about.text376')"/></span>
                             <h4 class="bold">{{$t('about.text300')}}</h4>  
                             <p>{{$t('about.text301')}}</p>     
                         </div> 
                     </div>
                     <div class="col-12">
                         <div class="text-center mt-4">
                            <a v-if="store.user.access_token" :href="`${static_vars.affilateURL}register`" target="_blank" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text291')}}</a>
                            <a v-else :href="`${static_vars.affilateURL}register`" target="_blank" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text291')}}</a>
                         </div>
                     </div>
                 </div>
             </div>
         </section>
         <section class="blockElement space bg-white">
             <div class="container">
                 <div class="row justify-content-center">
                     <div class="col-12 col-lg-8 mb-0 mb-md-4 text-center">
                         <h2>{{$t('about.text302')}}</h2>
                         <p>{{$t('about.text303')}}<br>{{$t('about.text304')}}
                         </p>
                     </div>
                     <!-- <div class="col-12">
                         <div class="text-center mt-4">
                             <router-link to="/affiliate-guide" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text305')}}</router-link>
                         </div>
                     </div> -->
                 </div>
             </div>
         </section>
         <section class="blockElement space automated4">
             <div class="container">
                 <div class="row">
                     <div class="col-12">
                         <h2 class="text-center mb-md-4 pb-4">{{$t('about.text306')}}</h2>
                         <ul class="listCounter mb-0">
                             <li class="d-flex align-items-center align-items-md-start flex-column flex-md-row pb-4">
                                 <span class="customXpad d-flex justify-content-center">
                                     <v-lazy-image class="d-block img-fluid mb-4" width="86" src="/assets/images/open-account.svg" :alt="$t('about.text377')" :title="$t('about.text378')"/>
                                 </span>
                                 <label class="d-block text-center text-md-start mb-0">
                                     <h4 class="mb-2 bold">{{$t('about.text307')}}</h4>
                                     <p>{{$t('about.text308')}}</p>
                                 </label>
                             </li>
                             <li class="d-flex align-items-center align-items-md-start flex-column flex-md-row pb-4">
                                 <span class="customXpad d-flex justify-content-center">
                                     <v-lazy-image class="d-block mb-4" src="/assets/images/Start-journey.svg" :alt="$t('about.text379')" :title="$t('about.text380')"/>
                                 </span>
                                 <label class="d-block text-center text-md-start mb-0">
                                     <h4 class="mb-2 bold">{{$t('about.text309')}}</h4>
                                     <p>{{$t('about.text310')}}</p>
                                 </label>
                             </li>
                             <li class="d-flex align-items-center align-items-md-start flex-column flex-md-row pb-4">
                                 <span class="customXpad d-flex justify-content-center">
                                     <v-lazy-image class="d-block mb-4" src="/assets/images/step-6.svg" :alt="$t('about.text381')" :title="$t('about.text382')"/>
                                 </span>
                                 <label class="d-block text-center text-md-start mb-0">
                                     <h4 class="mb-2 bold">{{$t('about.text311')}}</h4>
                                     <p>{{$t('about.text312')}}</p>
                                 </label>
                             </li>
                         </ul>

                     </div>
                      <div class="col-12">
                         <div class="text-center">
                            <a v-if="store.user.access_token" :href="`${static_vars.affilateURL}register`" target="_blank" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text291')}}</a>
                            <a v-else :href="`${static_vars.affilateURL}register`" target="_blank" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text291')}}</a>
                         </div>
                     </div>
                 </div>
             </div>
         </section>
         <section class="blockElement space bg-white automated5">
             <div class="container">
                 <div class="row">
                     <div class="col-12">
                         <h2 class="text-center mb-md-5 pb-4">{{$t('about.text313')}}</h2>
                     </div>
                     <div class="col-12 col-md-6 mb-4">
                         <div class="d-flex align-items-start">
                             <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/Transparency.svg" :alt="$t('about.text383')" :title="$t('about.text384')"/></span>
                             <div class="flex-none contentCalc">
                                 <h4 class="bold">{{$t('about.text314')}}</h4>  
                                 <p>{{$t('about.text315')}}</p>
                             </div> 
                         </div>
                     </div>
                     <div class="col-12 col-md-6 mb-4">
                         <div class="d-flex align-items-start">
                             <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/affiliate-team.svg" :alt="$t('about.text385')" :title="$t('about.text386')"/></span>
                             <div class="flex-none contentCalc">
                                 <h4 class="bold">{{$t('about.text316')}}</h4>  
                                 <p>{{$t('about.text317')}}</p>
                             </div> 
                         </div>
                     </div>
                     <div class="col-12 col-md-6 mb-4">
                         <div class="d-flex align-items-start">
                             <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/securityAdd.svg" :alt="$t('about.text387')" :title="$t('about.text388')"/></span>
                             <div class="flex-none contentCalc">
                                 <h4 class="bold">{{$t('about.text318')}}</h4>  
                                 <p>{{$t('about.text319')}}</p>
                             </div> 
                         </div>
                     </div>
                     <div class="col-12 col-md-6 mb-4">
                         <div class="d-flex align-items-start">
                             <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/Popularity.svg" :alt="$t('about.text389')" :title="$t('about.text390')"/></span>
                             <div class="flex-none contentCalc">
                                 <h4 class="bold">{{$t('about.text320')}}</h4>  
                                 <p>{{$t('about.text321')}}</p>
                             </div> 
                         </div>
                     </div>
                     <div class="col-12 col-md-6 mb-4">
                         <div class="d-flex align-items-start">
                             <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/no-lomits.svg" :alt="$t('about.text391')" :title="$t('about.text392')"/></span>
                             <div class="flex-none contentCalc">
                                 <h4 class="bold">{{$t('about.text322')}}</h4>  
                                 <p>{{$t('about.text323')}}</p>
                             </div> 
                         </div>
                     </div>
                     <div class="col-12 col-md-6 mb-4">
                         <div class="d-flex align-items-start">
                             <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/support.svg" :alt="$t('about.text393')" :title="$t('about.text394')"/></span>
                             <div class="flex-none contentCalc">
                                 <h4 class="bold">{{$t('about.text324')}}</h4>  
                                 <p>{{$t('about.text325')}}</p>
                             </div> 
                         </div>
                     </div>
                     <div class="col-12">
                         <div class="text-center mt-4">
                            <a v-if="store.user.access_token" :href="`${static_vars.affilateURL}register`" target="_blank" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text291')}}</a>
                            <a v-else :href="`${static_vars.affilateURL}register`" target="_blank" class="button fillBtn zulu_btn me-3 px-md-5">{{$t('about.text291')}}</a>
                         </div>
                     </div>
                 </div>
             </div>
         </section>
         <section class="blockElement space faqs automated6">
             <div class="container">
                 <div class="row">
                    <div class="col-12">
                         <h2 class="mb-md-4 pb-4">{{$t('about.text253')}}</h2>
                    </div>
                    <div class="col-12">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                             <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingOne">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                     {{$t('about.text326')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">
                                        <span class="d-block mb-2">{{$t('about.text327')}}</span>
                                        <span> {{$t('about.text327a')}} <a class="secondary" href="mailto:affiliates@zulutrade.com"> affiliates@zulutrade.com </a> {{$t('about.text327b')}}</span>
                                    </div>
                                 </div>
                             </div>
                             <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingFour">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                     {{$t('about.text328')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('about.text329')}} <a class="secondary" href="mailto:affiliates@zulutrade.com"> affiliates@zulutrade.com </a> {{$t('about.text329a')}}</div>
                                 </div>
                             </div>
                             <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingfive">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                                    {{$t('about.text330')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('about.text331')}}<router-link to="affiliate-guide" class="secondary"> {{$t('about.text219')}}</router-link>.</div>
                                 </div>
                             </div>
                             <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingsix">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                     {{$t('about.text332')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('about.text333')}}</div>
                                 </div>
                             </div>
                             <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingSeven">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                     {{$t('about.text334')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('about.text335')}}</div>
                                 </div>
                             </div>
                             <div class="accordion-item">
                                 <h2 class="accordion-header" id="flush-headingEight">
                                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                   {{$t('about.text336')}}
                                   </button>
                                 </h2>
                                 <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                   <div class="accordion-body">{{$t('about.text337')}} <a class="secondary" href="mailto:affiliates@zulutrade.com"> affiliates@zulutrade.com</a>. </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </section>
     </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
    }
</script>